// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
    text-align: center;
    margin-top: 20px;
}

.profile-img {
border-radius: 50%;
width: 100px;
height: 100px;
}

.profile-name {
font-size: 1.5rem;
color: #333;
}

.profile-email {
font-size: 1rem;
color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/style/Profile.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;AACA,kBAAkB;AAClB,YAAY;AACZ,aAAa;AACb;;AAEA;AACA,iBAAiB;AACjB,WAAW;AACX;;AAEA;AACA,eAAe;AACf,WAAW;AACX","sourcesContent":[".profile {\n    text-align: center;\n    margin-top: 20px;\n}\n\n.profile-img {\nborder-radius: 50%;\nwidth: 100px;\nheight: 100px;\n}\n\n.profile-name {\nfont-size: 1.5rem;\ncolor: #333;\n}\n\n.profile-email {\nfont-size: 1rem;\ncolor: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
