// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-button {
    background-color: #dc3545;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logout-button:hover {
    background-color: #c82333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}
`, "",{"version":3,"sources":["webpack://./src/style/LogoutButton.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;IACzB,yCAAyC;IACzC,2BAA2B;AAC/B","sourcesContent":[".logout-button {\n    background-color: #dc3545;\n    color: white;\n    font-size: 16px;\n    padding: 12px 24px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: all 0.3s ease;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.logout-button:hover {\n    background-color: #c82333;\n    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);\n    transform: translateY(-2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
