// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.logo {
    display: flex;
    align-items: center;
    margin-top: -20em;
    margin-bottom: -9em;
}

.logo img {
    height: 350px;
}

.logo h1 {
    font-family: tahoma;
    font-size: 250px;
    font-weight: 100;
}
`, "",{"version":3,"sources":["webpack://./src/style/Landing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".landing {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n.logo {\n    display: flex;\n    align-items: center;\n    margin-top: -20em;\n    margin-bottom: -9em;\n}\n\n.logo img {\n    height: 350px;\n}\n\n.logo h1 {\n    font-family: tahoma;\n    font-size: 250px;\n    font-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
